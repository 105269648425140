<template>
    <sui-container id="landing">
        <sui-grid vertical-align="middle" columns="two" centered>
            <sui-grid-row>
                <sui-grid-column :width="8" :mobile="16" :tablet="8" :computer="8">
                    <img alt="tsaro logo" src="../assets/tsaro-logo.png">
                </sui-grid-column>
                <sui-grid-column :width="8" :mobile="16" :tablet="8" :computer="8">
                    <sui-form @submit.prevent="onSubmit" :error="errorMessage !== ''">
                        <sui-form-field>
                            <label for="email">E-mail</label>
                            <input id="email" type="email" v-model="email"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label for="password">Password</label>
                            <input id="password" type="password" v-model="password"/>
                        </sui-form-field>
                        <sui-form-field>
                            <sui-checkbox label="Remember Me" v-model="remember"/>
                        </sui-form-field>
                        <error-message :title="errorMessage"></error-message>
                        <sui-button size="medium" color="blue" type="submit">Login</sui-button>
                    </sui-form>
                </sui-grid-column>
            </sui-grid-row>
        </sui-grid>
    </sui-container>
</template>

<script>
    import axios from "axios";
    import ErrorMessage from "@/components/ErrorMessage";

    import('../css/landing.css')
    export default {
        name: "Login",
        components: {ErrorMessage},
        data: function () {
            return {
                email: "",
                password: "",
                remember: false,
                errorMessage: ""
            }
        },
        methods: {
            onSubmit: async function () {
                const showError = () => {
                    this.errorMessage = "Email address or password is incorrect."
                }
                try {
                    const response = await axios({
                        "method": "POST",
                        "url": "/api/user/login",
                        "headers": {
                            "Content-Type": "application/json; charset=utf-8",
                        },
                        "data": {
                            "email": this.email,
                            "remember": this.remember,
                            "password": this.password
                        }
                    });

                    console.log(response.data);
                    if (Number.isInteger(response.data.userId)) {
                        this.error = false;
                        if (this.remember) {
                            this.$cookies.config("7d")
                        }

                        this.$cookies.set("tokenId", response.data.tokenId)
                        this.$cookies.set("token", response.data.token)

                        await this.$router.push("/dashboard/home");
                        return
                    }
                    showError();
                } catch (e) {
                    this.errorMessage = "Network connection error."
                }

            }
        }
    }
</script>

<style scoped>

</style>
